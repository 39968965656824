import React, { Component } from 'react';
import { Query } from 'react-apollo';
import TEAMS_QUERY from './queries/teams';
import Team from './Team';
class AllTeams extends Component {
  constructor(props) {
    super(props);
    var domain = window.location.host.split(".")[0];
    //var domain = "blaze";
    this.state = {
      warriors: domain === "warriors",
      blaze: domain === "blaze",
      both: domain !== "blaze" && domain !== "warriors",
      domain: domain
    };
  }

  render() {
    return (
      <Query query={TEAMS_QUERY}>
       {({ loading, error, data }) => {

          if (loading) return <div>Fetching games.....</div>
          if (error)   return <div>Error fetching games</div>

          const items = data.teams;

          if (!this.state.both){
            for( var i = 0; i < items.length; i++){ 
              if (items[i].teamTag === 'blaze' && !this.state.blaze) { 
                  items.splice(i, 1); 
              }
              if (items[i].teamTag === 'warriors' && !this.state.warriors) { 
                  items.splice(i, 1); 
              }
            }
          }

          return (
            <div className="container md-12 sm-6" >
              <div className="row">
                 {items.map(item => <Team key={item.id} team={item} />)}
              </div>
            </div>
          )
        }}
      </Query>
    );
  }

};
export default AllTeams;