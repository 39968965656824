import gql from "graphql-tag";
const WARRIORS_GAME_QUERY = gql`
query GameList {
  games (where: { teamTag:"warriors" } orderBy: date_ASC) {
    teamTag
    opponent
    location {
      latitude
      longitude
    }
    locationDescription
    liveStreamUrl
    liveStreamUrl2
    id
    date
  }
}
`;

const BLAZE_GAME_QUERY = gql`
query GameList {
  games (where: { teamTag:"blaze" } orderBy: date_ASC) {
    teamTag
    opponent
    location {
      latitude
      longitude
    }
    locationDescription
    liveStreamUrl
    liveStreamUrl2
    id
    date
  }
}
`;

export {WARRIORS_GAME_QUERY, BLAZE_GAME_QUERY };