import React from 'react';
import Moment from 'react-moment';

const Game = (props) => {
  var url = "https://www.google.com/maps/search/?api=1&query=" + props.game.location.latitude + "," + props.game.location.longitude;
  return (
      <div className="col-sm-4">
          <div className="card" style={{width: "18rem"}}>
            <div className="card-body">
              <h5 className="card-title">{props.game.opponent}</h5>
              <h3 className="card-title">{props.game.locationDescription}</h3>
              <p className="card-title">Date <Moment format="M/D/YYYY- h:mm A z" utc local>
                {props.game.date}
                </Moment>
              </p>
              <p>
                <a href={url} target="_blank" rel="noreferrer">Map</a>
              </p>
              {props.game.liveStreamUrl !== null && props.game.liveStreamUrl !== '' &&
                <p><a href={props.game.liveStreamUrl}>Live Stream</a></p>
              }
              {props.game.liveStreamUrl2 !== null && props.game.liveStreamUrl2 !== '' &&
                <p><a href={props.game.liveStreamUrl2}>Live Stream 2 (longer games or rain)</a></p>
              }
            </div>
          </div>
      </div>
  );
}
export default Game;