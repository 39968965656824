import React from 'react';
import './App.css';
import AllTeams from './components/AllTeams';


function App() {

  return (
      <div className="App">
        <div>
          <AllTeams/>
        </div>
      </div>
  );
}
export default App;