import React from 'react';
import AllGames from './AllGames';

const Team = (props) => {
  return (
      <div className="col-sm-12">
          <div className="card" style={{width: "100%"}}>
            <img src={props.team.teamLogo.url} style={{width: "100%"}} className="card-img-top" alt="{props.team.TeamName}"/>
            <div className="card-body">
              <h5 className="card-title">{props.team.TeamName}</h5>
              <AllGames key={props.team.id} team={props.team} />
            </div>
          </div>
      </div>
  );
}
export default Team;