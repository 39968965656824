import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { WARRIORS_GAME_QUERY, BLAZE_GAME_QUERY} from './queries/games';
import Game from './Game';
import PreviousGame from './PreviousGame';

class AllGames extends Component {
  constructor(props) {
    super(props);
    this.state = props;
  }

  render() {
    return (
      <Query query={this.state.team.teamTag === 'blaze' ? BLAZE_GAME_QUERY : WARRIORS_GAME_QUERY }>
       {({ loading, error, data }) => {

          if (loading) return <div>Fetching games.....</div>
          if (error)   return <div>Error fetching games</div>

          const items = data.games;

          const today = new Date();
          today.setHours(0,0,0,0);

          const currentGames = items.filter(game => new Date(game.date) > today);
          const previousGames = items.filter(game => new Date(game.date) <= today && game.liveStreamUrl !== null && game.liveStreamUrl !== '');

          return (
            <div className="container mt-4">
              <div className="row">
                 {currentGames.map(item => <Game key={item.id} game={item} />)}
              </div>
              {previousGames.length > 0 &&
                <div className="row">
                   <h3>Previous Games</h3>
                </div>
              }
              {previousGames.length > 0 &&
                <div className="row">
                   {previousGames.map(item => <PreviousGame key={item.id} game={item} />)}
                </div>
              }
            </div>
          )
        }}
      </Query>
    );
  }

};
export default AllGames;