import gql from "graphql-tag";
const TEAMS_QUERY = gql`
query TeamList {
  teams(orderBy: teamTag_ASC) {
    id
    teamName
    teamTag
    teamLogo {
      id
      url
    }
  }
}
`;
export default TEAMS_QUERY;