import React from 'react';
import Moment from 'react-moment';

const PreviousGame = (props) => {
  return (
      <div className="col-sm-4">
          <div className="card" style={{width: "18rem"}}>
            <div className="card-body">
              <p className="card-title">{props.game.opponent} - <Moment format="M/D/YYYY- h:mm A z" utc local>
                {props.game.date}
                </Moment>
              </p>
              {props.game.liveStreamUrl !== null &&
                <p><a href={props.game.liveStreamUrl}>Live Stream</a></p>
              }
              {props.game.liveStreamUrl2 !== null &&
                <p><a href={props.game.liveStreamUrl2}>Live Stream 2 (longer games or rain)</a></p>
              }
            </div>
          </div>
      </div>
  );
}
export default PreviousGame;